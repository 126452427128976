import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Input } from "@fundrecs/ui-library";
import { SidePanelSection } from "./SidePanelSection";
import { ReactComponent as SubAccountsIcon } from "../../../../icons/subAccounts.svg";
import { Badge } from "../../../layout/Layout";

const SubAccountSection = observer(({ panelOpen, openOnClick, updateUrl = null, allSubAccounts = [], rowCounts = [], thisIsACashRec = false }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const createLink = (text, subAccount, note, colour, currencyId = null) => {
    let style = { fontWeight: "700", cursor: "pointer", backgroundColor: "lightgray", color: "black", width: `${text.length * 15}px` };
    if (colour === "green") {
      style.backgroundColor = "#E8F6EE"; //green
      style.color = "#15A251";
    } else if (colour === "blue") {
      style.backgroundColor = "#E9EFFF"; //blue
      style.color = "#1E5EFF";
    }

    return (
      <span
        onClick={() => {
          if (updateUrl !== null) {
            updateUrl(subAccount, note, currencyId);
          }
        }}
      >
        <Badge text={text} style={style} />
      </span>
    );
  };

  //Find the sub accounts for which there is no data to append to the end of the list
  const subAccountNames = rowCounts.map((it) => it.name);
  const subAccountsWithNoRows = allSubAccounts.filter((subAccount) => {
    return !subAccountNames.includes(subAccount.name);
  });

  return (
    <SidePanelSection
      panelOpen={panelOpen}
      sectionOpen={panelOpen === "subAccounts"}
      openOnClick={openOnClick}
      title="Sub accounts"
      icon={<SubAccountsIcon />}
      accordianId={"side-panel-sub-account-accordian"}
    >
      <div className="pt-8 pl-8 pr-8 pb-8 mb-24">
        <Input
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          value={searchFilter}
          search={true}
          placeholderText="Search"
        />
      </div>
      {rowCounts.map((counts) => {
        const subAccount = allSubAccounts.find((it) => it.name === counts.name);
        return searchFilter.length === 0 || counts.name.toLowerCase().includes(searchFilter.toLowerCase()) ? (
          <div className="d-flex justify-content-between mb-8">
            <div title={counts.name} className="ellipsize-text" style={{ width: thisIsACashRec ? "50%" : "70%" }}>
              <Text variant="secondary" size="xs" weight="medium">
                {counts.name}
              </Text>
            </div>
            {thisIsACashRec ? (
              <div title={counts.name} className="ellipsize-text" style={{ width: "10%" }}>
                <Text variant="secondary" size="xs" weight="medium">
                  {counts.currencyCode}
                </Text>
              </div>
            ) : (
              ""
            )}
            <div>
              {createLink(counts ? counts.unprocessedRowsCount : 0, subAccount, "Unprocessed", "blue", thisIsACashRec ? counts.currencyId : null)}
              {createLink(counts ? counts.processedRowsCount : 0, subAccount, "Processed", "green", thisIsACashRec ? counts.currencyId : null)}
            </div>
          </div>
        ) : (
          ""
        );
      })}
      {subAccountsWithNoRows.map((subAccount) => {
        return searchFilter.length === 0 || subAccount.name.toLowerCase().includes(searchFilter.toLowerCase()) ? (
          <div className="d-flex justify-content-between mb-8">
            <div title={subAccount.name} className="ellipsize-text" style={{ width: "70%" }}>
              <Text variant="secondary" size="xs" weight="medium">
                {subAccount.name}
              </Text>
            </div>
            <div>
              {createLink(0, subAccount, "Unprocessed", "blue", null)}
              {createLink(0, subAccount, "Processed", "green", null)}
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </SidePanelSection>
  );
});

export { SubAccountSection };
