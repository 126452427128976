import { IconDownArrow, DropdownList, DropdownListItem, DropdownItem, ItemBody } from "@fundrecs/ui-library";
import "./toolbarButtons.css";

const CurrencyDropdown = ({ selectedCurrency, onClick, currencies, disabled = false }) => {
  return (
    <div className="dropdown mb-16 ml-16" style={{ width: "100px" }}>
      <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
        <span>{selectedCurrency ? selectedCurrency.code : ""}</span>
        <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
      </div>
      <DropdownList>
        {currencies.map((currency, index) => {
          return (
            <DropdownListItem
              onClick={() => {
                onClick(currency);
              }}
              key={index + 1}
            >
              <DropdownItem active={null} index={0}>
                <ItemBody title={currency.code}>{currency.code}</ItemBody>
              </DropdownItem>
            </DropdownListItem>
          );
        })}
      </DropdownList>
    </div>
  );
};

export { CurrencyDropdown };
