import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance, R, C10, Panel, PanelBody, PanelFooter, Heading, Text, TextArea, Input, Button } from "@fundrecs/ui-library";
import { MODALS, STATUS } from "../../../utils/enums";
import { formatMatchingRuleToEdit } from "../../rules/dataTransform";
import { useStore } from "../../../store/Store";
import { PATH } from "../../../utils/urls";

const CreateMatchingRule = observer(({ selectedRule, deselectRule }) => {
  const { matchingRuleStore, recTypeStore, comparatorFunctionStore } = useStore();
  const ruleObject = { name: "", description: "" };
  const warningMessageObject = { name: "", description: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [matchingRule, setMatchingRule] = useState(ruleObject);
  const [warningMessage, setWarningMessage] = useState(warningMessageObject);
  const navigate = useNavigate();
  const nameMaxLength = 100;
  const descriptionMaxLength = 150;
  const NEW = "NEW";
  const MODE = selectedRule && selectedRule.status ? selectedRule.status : NEW;
  const ruleName = selectedRule ? selectedRule.name : "";
  const text = {
    NEW: { title: "New Matching Rule", description: "Please add the name and description for the new rule", buttonText: "Create matching rule" },
    [STATUS.PUBLISHED.status]: {
      title: `Clone Matching Rule (${ruleName})`,
      description: "Please add the name and description for the cloned rule",
      buttonText: "Clone rule",
    },
    [STATUS.DRAFT.status]: {
      title: `Edit Matching Rule (${ruleName})`,
      description: "Edit this rule's name and description",
      buttonText: "Edit rule",
    },
  };

  if (selectedRule && selectedRule.id !== matchingRule.id) {
    setMatchingRule(selectedRule);
  }

  const closeModal = () => {
    deselectRule();
    setMatchingRule({ name: "", description: "" });
    setWarningMessage(warningMessageObject);
    modalInstance(MODALS.CREATE_MATCHING_RULE).hide();
  };

  const createMatchingRule = async () => {
    let warningMessageObject = { name: "", description: "" };
    let warning = false;
    if (matchingRule.name.length < 1) {
      warningMessageObject = { ...warningMessageObject, name: "Please enter a name for the rule" };
      warning = true;
    }

    if (matchingRule.description.length < 1) {
      warningMessageObject = { ...warningMessageObject, description: "Please enter a description for the rule" };
      warning = true;
    }

    if (!warning) {
      const reqBody = { name: matchingRule.name, description: matchingRule.description };
      const selectedRecType = recTypeStore.getSelectedRecType();
      const response = await matchingRuleStore.createMatchingRule(teamId, selectedRecType ? selectedRecType.id : null, reqBody);
      if (response.status === 200) {
        if (MODE === STATUS.PUBLISHED.status) {
          matchingRuleStore.updateDraftMatchingRule(
            formatMatchingRuleToEdit(response.data, selectedRule.matchingRule, comparatorFunctionStore.getFunctions(), recTypeStore.getRecTypeTmos())
          );
        }
        recTypeStore.fetchMatchingRuleDetails(teamId, selectedRecType.id);
        closeModal();
        navigate(`${PATH.MATCHING_RULE_BUILDER}?teamId=${teamId}&recTypeId=${selectedRecType.id}&matchingRuleId=${response.data.id}`, { replace: false });
      }
    }
    setWarningMessage(warningMessageObject);
  };

  return (
    <Panel panelId={MODALS.CREATE_MATCHING_RULE}>
      <PanelBody>
        <div className="panel-header">
          <div style={{ marginLeft: "16px" }} className="panel-header-left">
            <Heading variant="h4" element="span">
              {text[MODE]["title"]}
            </Heading>
          </div>
          <div className="panel-header-right">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            ></button>
          </div>
          <div className="panel-header-clear"></div>
        </div>
        <R>
          <C10>
            <Text size="sm" variant="secondary" weight="regular">
              {text[MODE]["description"]}
            </Text>
          </C10>
        </R>
        <div className="pt-32">
          <R props="mb-32">
            <C10>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ alignSelf: "flex-start" }}>
                  <Text size="sm" weight="medium" variant="secondary" element="div">
                    Rule name *
                  </Text>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <Text size="sm" weight="regular" variant="tertiary" element="div">{`${matchingRule.name.length}/${nameMaxLength}`}</Text>
                </div>
              </div>
              <Input
                onChange={(event) => {
                  if (event.target.value.length <= nameMaxLength) {
                    setMatchingRule({ ...matchingRule, name: event.target.value });
                  }
                }}
                value={matchingRule.name}
                warning={warningMessage.name.length > 1}
                warningMessage={warningMessage.name}
              />
            </C10>
          </R>
          <R props="mb-32">
            <C10>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ alignSelf: "flex-start" }}>
                  <Text size="sm" weight="medium" variant="secondary" element="div">
                    Rule description *
                  </Text>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <Text size="sm" weight="regular" variant="tertiary" element="div">{`${matchingRule.description.length}/${descriptionMaxLength}`}</Text>
                </div>
              </div>
              <TextArea
                onChange={(event) => {
                  if (event.target.value.length <= descriptionMaxLength) {
                    setMatchingRule({ ...matchingRule, description: event.target.value });
                  }
                }}
                value={matchingRule.description}
                warning={warningMessage.description.length > 1}
                warningMessage={warningMessage.description}
              />
            </C10>
          </R>
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          color="tertiary"
          onClick={() => {
            closeModal();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          onClick={() => {
            createMatchingRule();
          }}
          size="md"
          disabled={false}
        >
          <Text size="sm">{text[MODE]["buttonText"]}</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateMatchingRule };
