import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, Dropdown, DropdownButton } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { EditableNumber } from "../editable/EditableNumber";

const TransactionToleranceAccountSetup = observer(({ recTypeId, accountId, accountVersion, baseCurrencyCode = "" }) => {
  const { accountStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [transactionTolerance, setTransactionTolerance] = useState(0);
  const [localAccountVersion, setLocalAccountVersion] = useState(accountVersion);
  const [initialised, setInitialised] = useState(false);

  const findRecTypeAccountSettings = async () => {
    const resp = await accountStore.findRecTypeAccountSettings(teamId, accountId, recTypeId);
    if (resp.status === 200 && resp.data && ![null, undefined].includes(resp.data.transactionTolerance)) {
      setTransactionTolerance(resp.data.transactionTolerance);
    }
  };

  const saveTransactionTolerance = async (transactionTolerance) => {
    const resp = await accountStore.setTransactionTolerance(teamId, accountId, recTypeId, localAccountVersion, transactionTolerance);
    if (resp.status === 200) {
      setTransactionTolerance(transactionTolerance);
      setLocalAccountVersion(localAccountVersion + 1);
    }
  };

  if (!initialised && recTypeId && accountId) {
    setInitialised(true);
    findRecTypeAccountSettings();
  }
  return (
    <div>
      <Text size="sm" weight="bold">
        Transaction tolerance
      </Text>
      <div className="mt-8">
        <Text size="sm" weight="regular">
          Transaction tolerance refers to the acceptable <b>Amount Difference</b> between two sets of data when assigning to the <b>Match category</b>. If the{" "}
          <b>Amount Difference</b> is equal to or falls below this limit, the <b>Match</b> is successful.
        </Text>
      </div>
      <div className="d-flex mt-32 mb-32">
        <div className="mr-32" style={{ width: "300px" }}>
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Category
            </Text>
          </div>
          <Dropdown spacers={["mr-12"]}>
            <DropdownButton disabled={true} size="sm">
              Match
            </DropdownButton>
          </Dropdown>
        </div>
        <div className="mr-32" style={{ width: "300px" }}>
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Match on
            </Text>
          </div>
          <Dropdown spacers={["mr-12"]}>
            <DropdownButton disabled={true} size="sm">
              Amount difference
            </DropdownButton>
          </Dropdown>
        </div>
      </div>

      <div className="d-flex mt-32 mb-32">
        <div className="mr-32">
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Fund base currency
            </Text>
          </div>
          <Text size="sm" weight="regular">
            {baseCurrencyCode}
          </Text>
        </div>
        <div>
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Max allowable difference
            </Text>
          </div>
          <EditableNumber
            value={transactionTolerance}
            name=""
            label=""
            onSave={(value) => {
              saveTransactionTolerance(value);
            }}
            warningText=""
            disabled={
              !isUserAuthorized({
                teamId: teamId,
                allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]),
              })
            }
          />
        </div>
      </div>
      <div>
        <Text size="sm" weight="regular" variant="secondary">
          Max difference default is 0. To update select 'Edit', then update the amount and save.
        </Text>
      </div>
      <div>
        <Text size="sm" weight="regular" variant="secondary">
          Base currency can be updated in the fund setup
        </Text>
      </div>
    </div>
  );
});

export { TransactionToleranceAccountSetup };
