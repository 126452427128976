import { useState, useReducer, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { VerticalMenuDropdown, VerticalMenuListItem, Text } from "@fundrecs/ui-library";
import { MainContainer, PageTitleArea } from "../layout/Layout";
import { STATUS } from "../../utils/enums.js";
import { useStore } from "../../store/Store.js";
import { getDateStringFromTimestamp, getDayStringFromTimestamp, getMonthStringFromTimestamp, getYearStringFromTimestamp } from "../../utils/dates";
import { PendingCompletedRecView } from "./PendingCompletedRecView.js";
import { OpenRecView } from "./OpenRecView.js";
import { PATH } from "../../utils/urls.js";
import { ManualFileUploadModal } from "../uploads/ManualFileUploadModal.js";
import { StatusBadge } from "../reusable/StatusBadge.js";
import { handleTeamChange, ifNullUndefinedArray } from "../../utils/utils";
import { matchingReducer, getInitialMatchingConfig, MatchingContext, MatchingDispatchContext } from "./matchingReducer";
import { CashRecBalanceTable } from "./cashRec/CashRecBalanceTable";

const Reconciliations = observer(() => {
  const { recStore, recTypeStore, teamStore, globalMappingStore, uiStore, accountStore } = useStore();
  const rec = recStore.getSelectedRec();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const accountId = Number(searchParams.get("accId"));
  const recId = Number(searchParams.get("recId"));
  const recTypeId = Number(searchParams.get("recTypeId"));
  const teamId = Number(searchParams.get("teamId"));
  const selectedTeam = teamStore.getSelectedTeam();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [initialised, setInitialised] = useState(false);
  const [globalMapping, setGlobalMapping] = useState(null);
  const [subAccounts, setSubAccounts] = useState([]);
  const [rowGroupSchema, setRowGroupSchema] = useState([]);
  const [diffCalcHidden, setDiffCalcHidden] = useState(false);
  const [initialiseRowCounts, setInitialiseRowCounts] = useState(false);
  const [rowCounts, setRowCounts] = useState([]);
  const [balanceTableData, setBalanceTableData] = useState([]);

  useEffect(() => {
    handleTeamChange(selectedTeam ? selectedTeam.id : null, teamId, () => {
      navigate(`${PATH.REC_TYPES}?teamId=${teamId}`);
    });
  }, [teamId, navigate, selectedTeam]);

  const globalMappingsEnabledForAccount =
    globalMapping && globalMapping.enabled && globalMapping.status === "APPROVED" && globalMapping.accounts.find((it) => it.id === accountId) ? true : false;

  let recType = recTypeStore.getSelectedRecType();
  recType = recType ? recType : recTypeStore.fetchRecTypeAndSettings(teamId, recTypeId);
  const thisIsACashRec = rec.cashRec;

  const getUploadedFilesForRec = async () => {
    let uploadedFiles = await recStore.getUploadedFilesForRec(teamId, recId);
    uploadedFiles = uploadedFiles.length ? uploadedFiles.filter((file) => file.status !== "DELETED") : uploadedFiles;
    setUploadedFiles(uploadedFiles);
  };

  const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";

  const getGlobalMapping = async () => {
    const resp = await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
    if (resp.status === 200 && resp.data.length) {
      setGlobalMapping(resp.data[0]);
    }
  };

  const formatRowCountsForCashRec = (countsResponse) => {
    return countsResponse.balance.map((subAccountData) => {
      return {
        name: subAccountData.subAccountName,
        currencyCode: subAccountData.currencyCode,
        unprocessedRowsCount: subAccountData["unProcessedRowsCount"],
        processedRowsCount: subAccountData["processedRowsCount"],
        currencyId: subAccountData.currencyId,
      };
    });
  };

  const refreshCountsForAllSubAccounts = async () => {
    const rowCountResponse = thisIsACashRec ? await recStore.fetchRowCountsPerCurrency(teamId, recId) : await recStore.fetchRowCounts(teamId, recId);
    const formattedCounts = thisIsACashRec ? formatRowCountsForCashRec(rowCountResponse) : rowCountResponse;
    setRowCounts(formattedCounts);
    if (thisIsACashRec) {
      setBalanceTableData(rowCountResponse);
    }
    return formattedCounts;
  };

  const initaliseClientsAccountsAndRec = async () => {
    setInitialised(true);
    //Ensure the clients, accounts & sub accounts have been fetched
    const clientsAccountsPerRecType = recTypeStore.getClientsAccountsPerRecType();
    if (!clientsAccountsPerRecType.length) {
      await recTypeStore.fetchClientsAccountsPerRecType(teamId, recTypeId);
    }
    const selectedAccount = recTypeStore.getAccountById(accountId);
    if (!recType) {
      recTypeStore.fetchRecTypeAndSettings(teamId, recTypeId);
    }
    if (!selectedAccount) {
      let fundName = "this fund";
      const accountsPerTeam = await accountStore.fetchAccountsForTeam(teamId);
      if (accountsPerTeam.length) {
        const fund = accountsPerTeam.find((it) => it.id === accountId);
        fundName = fund ? fund.name : fundName;
      }
      uiStore.addNotification("error", `To view this rec, please enable ${fundName} for ${recType.name}`);
    }
    recStore.getRecById(teamId, recId);
    setSelectedAccount(selectedAccount);
    const subAccountsResp = await recTypeStore.getSubAccounts(accountId);
    setSubAccounts(subAccountsResp);
    const rowGroupSchemaResp = await recTypeStore.getRowGroupSchema(teamId, recTypeId);
    setRowGroupSchema(rowGroupSchemaResp);
    getGlobalMapping();
    getUploadedFilesForRec();
  };

  if (!initialiseRowCounts && Object.keys(rec).length) {
    setInitialiseRowCounts(true);
    refreshCountsForAllSubAccounts();
  }

  const pollRecStatus = async (status = recStatus) => {
    //Check that the user is still on the rec page
    if (window.location.href.includes("/rec?")) {
      const resp = await recStore.pollRecStatus(teamStore.getSelectedTeam().id, rec.id);
      if (resp.status === 200 && status !== resp.data.status) {
        window.location.reload();
      } else {
        setTimeout(() => {
          pollRecStatus(status);
        }, 10000);
      }
    }
  };

  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  if (recTypes.length && !initialised) {
    initaliseClientsAccountsAndRec();
  }

  const [matchingConfig, dispatch] = useReducer(matchingReducer, getInitialMatchingConfig());

  const openRecView = ![STATUS.APPROVED.status, STATUS.PENDING.status, STATUS.REPORT_CREATE.status, STATUS.REPORT_CLEAR.status].includes(recStatus);
  const recLoaded = Object.keys(rec).length;

  return (
    <MainContainer>
      <MatchingContext.Provider value={matchingConfig}>
        <MatchingDispatchContext.Provider value={dispatch}>
          <PageTitleArea
            customTitle={
              <div className="pb-8">
                <Text weight="regular" size="md" element="span">
                  {`${recType && recType.name ? recType.name : ""} / ${selectedAccount && selectedAccount.name ? selectedAccount.name : ""} / `}
                </Text>
                <Text element="span">{!rec || !rec.startDate ? "" : getDateStringFromTimestamp(rec.startDate)}</Text>
              </div>
            }
            backButtonUrl={`${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${recTypeId}${
              rec && rec.startDate ? `&date=${getDateStringFromTimestamp(rec.startDate)}` : ""
            }&client=all`}
            backButtonDescription={`Back to ${recType && recType.name ? recType.name : ""} dashboard`}
            title={`${recType && recType.name ? recType.name : ""} > ${selectedAccount && selectedAccount.name ? selectedAccount.name : ""} > ${
              !rec || !rec.startDate ? "" : getDateStringFromTimestamp(rec.startDate)
            }`}
            description={<StatusBadge status={recStatus} recStatus={true} />}
            borderBottom={false}
            props={
              <>
                <span className="pl-32"></span>
                <div style={{ borderRadius: "100%", height: "40px", width: "40px", border: "1px solid #CDD0D7", paddingTop: "5px", textAlign: "center" }}>
                  <VerticalMenuDropdown>
                    {openRecView ? (
                      <VerticalMenuListItem
                        text={`${diffCalcHidden ? "Show" : "Hide"} Difference Calculator`}
                        onClick={() => {
                          setDiffCalcHidden(!diffCalcHidden);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <>
                      {rec && rec.startDate && recType && recType.name && selectedAccount ? (
                        <Link
                          className="text-decoration-none"
                          to={`${PATH.REC_AUDIT_TRAIL}?teamId=${teamId}&recTypeId=${recType.id}&recType=${recType.name}&account=${selectedAccount.name}&accId=${
                            selectedAccount.id
                          }&recId=${recId}&date=${getDateStringFromTimestamp(rec.startDate)}`}
                        >
                          <VerticalMenuListItem text="View Audit Trail" onClick={() => {}} />
                        </Link>
                      ) : (
                        ""
                      )}
                    </>
                  </VerticalMenuDropdown>
                </div>
              </>
            }
          />
          {thisIsACashRec ? <CashRecBalanceTable balanceTableData={balanceTableData} /> : ""}
          {recLoaded && !openRecView ? (
            <PendingCompletedRecView
              globalMappingsEnabledForAccount={globalMappingsEnabledForAccount}
              globalMapping={globalMapping}
              selectedAccount={selectedAccount}
              uploadedFiles={uploadedFiles}
              pollRecStatus={pollRecStatus}
              subAccounts={subAccounts}
              rowGroupSchema={rowGroupSchema}
              rowCounts={rowCounts}
            />
          ) : recLoaded ? (
            <>
              <OpenRecView
                recType={recType}
                selectedAccount={selectedAccount}
                uploadedFiles={uploadedFiles}
                pollRecStatus={pollRecStatus}
                globalMappingsEnabledForAccount={globalMappingsEnabledForAccount}
                globalMapping={globalMapping}
                subAccounts={subAccounts}
                rowGroupSchema={rowGroupSchema}
                diffCalcHidden={diffCalcHidden}
                rowCounts={rowCounts}
                refreshCountsForAllSubAccounts={refreshCountsForAllSubAccounts}
              />
              <ManualFileUploadModal
                teamId={teamId}
                recType={recType}
                selectedDate={
                  new Date(getYearStringFromTimestamp(rec.startDate), getMonthStringFromTimestamp(rec.startDate) - 1, getDayStringFromTimestamp(rec.startDate))
                }
              />
            </>
          ) : (
            ""
          )}
        </MatchingDispatchContext.Provider>
      </MatchingContext.Provider>
    </MainContainer>
  );
});

export { Reconciliations };
