import { useState } from "react";
import { IconDownArrow, IconUpArrow, R, C10, C2 } from "@fundrecs/ui-library";
import "./accordianPanel.css";

const AccordianPanel = ({
  title,
  openDefault = false,
  expandTo = "100%",
  expandFrom = "0px",
  elementId = "accordian-body",
  alignIcon = "right",
  style = { borderBottom: "1px solid #F1F5F9" },
  children,
}) => {
  const [open, setOpen] = useState(openDefault);
  const accordianBodyElement = document.getElementById(elementId);
  if (accordianBodyElement) {
    if (open) {
      accordianBodyElement.style.height = expandTo;
    } else {
      accordianBodyElement.style.height = expandFrom;
    }
  }
  return (
    <div className="pt-16 pb-16 " style={style}>
      <R>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <R>
            {alignIcon === "left" ? (
              <div className="d-flex">
                <div className="mr-8">{open ? <IconUpArrow className="btn-md-svg mb-8" /> : <IconDownArrow className="btn-lg-svg" />}</div>
                {title}
              </div>
            ) : (
              <>
                <C10>{title}</C10>
                <C2>{open ? <IconUpArrow className="btn-md-svg" /> : <IconDownArrow className="btn-lg-svg" />}</C2>
              </>
            )}
          </R>
        </span>
      </R>
      <div id={elementId} className="accordian-body" style={{ height: open ? expandTo : expandFrom }}>
        {children}
      </div>
    </div>
  );
};

export { AccordianPanel };
