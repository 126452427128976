import { useState, useRef, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text, R, C4, ToolTip, IconDownload, Button, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store.js";
import { formatMatchedRowsForTable, formatUnmatchedRowsForTable } from "./recTableLogic.js";
import { Table } from "../ag-grid/Ag-grid";
import { ManageLayout } from "../layout/Layout.js";
import { PendingRecToolbar } from "./reconciliationToolbars.js";
import { AUTHORITIES, MODALS, STATUS } from "../../utils/enums.js";
import { ALL_ROWS, getLastPageNo, ROW_FETCH_LIMIT } from "../../utils/rowBatchUtils.js";
import { RunningMatchingRulesPlaceholder } from "./matchingRules/RunningMatchingRulesPlaceholder.js";
import { PendingGlobalMappingsAlert } from "../globalMapping/PendingGlobalMappingsAlert.js";
import { getDateStringFromTimestamp, getDayStringFromTimestamp, getMonthStringFromTimestamp, getYearStringFromTimestamp } from "../../utils/dates.js";
import { AuthWrapper } from "../AuthorizationWrapper.js";
import { WarningModal } from "../WarningModal.js";
import { RowBatchSelector } from "./toolbars/RowBatchSelector.js";
import { ColumnViewEditor } from "./toolbars/ColumnViewEditor.js";
import { AnimatedPanelToggleButton } from "../reusable/AnimatedPanel/AnimatedPanelToggleButton.js";
import { RecSidePanel } from "./sidePanels/RecSidePanel.js";
import { RecDetailsPanel } from "./sidePanels/recDetailsSidePanel/RecDetailsPanel.js";
import { SubAccountsDropdown } from "./toolbars/SubAccountsDropdown.js";
import { CurrencyDropdown } from "./toolbars/CurrencyDropdown.js";

const PendingCompletedRecView = observer(
  ({
    selectedAccount,
    uploadedFiles = [],
    pollRecStatus,
    globalMappingsEnabledForAccount,
    globalMapping,
    subAccounts = [],
    rowGroupSchema = [],
    rowCounts,
  }) => {
    const gridRef = useRef(null);
    const { recStore, recTypeStore, meStore, matchingRuleStore, rolesStore, teamStore } = useStore();
    const [searchParams] = useSearchParams();
    const accountId = Number(searchParams.get("accId"));
    const recTypeId = Number(searchParams.get("recTypeId"));
    const teamId = Number(searchParams.get("teamId"));
    const rec = recStore.getSelectedRec();
    const recType = recTypeStore.getSelectedRecType();
    const recStatus = rec.fourEyes && rec.fourEyes.status ? rec.fourEyes.status : "";
    const matchingRules = matchingRuleStore.getMatchingRules();
    const rejectApproveDisabled = !rec || recStatus !== STATUS.PENDING.status || meStore.getEmail() === rec.fourEyes.submittedBy.userName;
    const [rows, setRows] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [selectedSubAccount, setSelectedSubAccount] = useState(null);
    const ALL_SUB_ACCOUNTS = useMemo(() => ({ name: "All sub accounts", id: null }), []);
    const [displayNumberOfRows, setDisplayNumberOfRows] = useState(ROW_FETCH_LIMIT);
    const [rowCountsForTable, setRowCountsForTable] = useState(null);
    const [recDetailsOpen, setRecDetailsOpen] = useState(null);
    const [unmatchedColumns, setUnmatchedColumns] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const thisIsACashRec = rec.cashRec;

    const [initialised, setInitialised] = useState(false);

    const rejectRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.rejectRec(teamId, rec.id, rec.version);
    };

    const approveRec = async () => {
      const rec = recStore.getSelectedRec();
      recStore.approveRec(teamId, rec.id, rec.version);
    };

    const cancelReport = async () => {
      if (recStatus === STATUS.REPORT_CREATE.status) {
        const rec = recStore.getSelectedRec();
        recStore.cancelReport(teamId, rec.id, rec.version);
        modalInstance(MODALS.WARNING).hide();
      }
    };

    if ([STATUS.IMPORTING_DATA.status, STATUS.IN_PROGRESS.status, STATUS.REPORT_CLEAR.status, STATUS.REPORT_CREATE.status].includes(recStatus)) {
      pollRecStatus();
    }

    const fetchRowCounts = async (currencyId = null) => {
      let counts = {};
      for (let i = 0; i < subAccounts.length; i++) {
        const rowCountForTableResponse = await recStore.fetchRowCountForTable(teamId, rec.id, subAccounts[i].id, currencyId);
        if (rowCountForTableResponse.length) {
          counts[subAccounts[i].id] = rowCountForTableResponse[0]["rowGroupsCount"];
        }
      }
      setRowCountsForTable(counts);
    };

    const fetchRows = async (subAccount, numberOfRows = displayNumberOfRows, currencyId = selectedCurrency ? selectedCurrency.id : null) => {
      setRows(null);
      numberOfRows =
        numberOfRows === ALL_ROWS ? (subAccount.name === ALL_SUB_ACCOUNTS.name ? renderTotalRowCount : rowCountsForTable[subAccount.id]) : numberOfRows;
      let rowGroups = [];
      /*
      const fetch = (pageNo) => {
        return subAccount.name === ALL_SUB_ACCOUNTS.name
          ? recStore.getRowGroupsForAllSubAccounts(teamId, rec.id, rec.version, pageNo)
          : recStore.getRowGroupsForSubAccount(teamId, rec.id, subAccount.uuid, rec.version, pageNo);
      };

      const responses = await fetchBatches(numberOfRows, (pageNo) => fetch(pageNo));
      responses.forEach((resp) => (rowGroups = [...rowGroups, ...resp]));
      */
      //Above commented logic sends the requests concurently, rather than the loop below which sends them consecutively
      for (let pageNo = 0; pageNo <= getLastPageNo(numberOfRows); pageNo++) {
        const resp =
          subAccount.name === ALL_SUB_ACCOUNTS.name
            ? await recStore.getRowGroupsForAllSubAccounts(teamId, rec.id, rec.version, pageNo, currencyId)
            : await recStore.getRowGroupsForSubAccount(teamId, rec.id, subAccount.uuid, rec.version, pageNo, currencyId);
        rowGroups = [...rowGroups, ...resp];
      }

      const tableData = formatMatchedRowsForTable(rowGroupSchema, rowGroups, matchingRules, true);
      setRows(tableData.rows);
      setHeaders(tableData.columns);
      setSelectedSubAccount(subAccount);
    };

    const downloadFile = async () => {
      gridRef.current.api.exportDataAsCsv({ fileName: createFileName() });
    };

    const createFileName = () => {
      const fileName = `${recType.name} reconciliation (${STATUS[rec.fourEyes.status]["text"]})- ${selectedAccount.name}, ${
        selectedSubAccount ? selectedSubAccount.name : ""
      } - ${getDateStringFromTimestamp(rec.startDate)}`;
      const fullStopsRemoved = fileName.replaceAll(".", "");
      return fullStopsRemoved;
    };

    const initialiseData = async () => {
      setInitialised(true);
      let currenciesToLoad = [];
      if (thisIsACashRec) {
        rowCounts.forEach((it) => {
          const currency = { code: it.currencyCode, id: it.currencyId };
          if (!currenciesToLoad.find((it) => it.code === currency.code)) {
            currenciesToLoad.push({ code: it.currencyCode, id: it.currencyId });
          }
        });
        setCurrencies(currenciesToLoad);
      }
      const currency = currenciesToLoad.length ? currenciesToLoad[0] : null;
      const currencyId = currency ? currency.id : null;
      fetchRows(ALL_SUB_ACCOUNTS, displayNumberOfRows, currencyId);
      fetchRowCounts(currencyId);
      setSelectedCurrency(currency);
      const unmatchedRows = await recStore.getUnmatchedRows(teamStore.getSelectedTeam().id, rec.id, subAccounts[0]["uuid"], rec.version, 0, currencyId);
      const columns = formatUnmatchedRowsForTable(unmatchedRows).columns;
      setUnmatchedColumns(columns);
    };

    if (!initialised && rec && rec.id && recType && subAccounts.length && rowGroupSchema.length && rowCounts.length) {
      initialiseData();
    }

    const updateSubAccountForCashRec = (subAccount, currencyId = null) => {
      if (subAccount === ALL_SUB_ACCOUNTS) {
        let currenciesToLoad = [];
        rowCounts.forEach((it) => {
          const currency = { code: it.currencyCode, id: it.currencyId };
          if (!currenciesToLoad.find((it) => it.code === currency.code)) {
            currenciesToLoad.push({ code: it.currencyCode, id: it.currencyId });
          }
        });
        const currency = selectedCurrency ? selectedCurrency : currenciesToLoad[0];
        setSelectedCurrency(currency);
        setCurrencies(currenciesToLoad);
        fetchRows(ALL_SUB_ACCOUNTS, displayNumberOfRows, currency["id"]);
      } else {
        const countsPerSubaccount = rowCounts.filter((it) => it.name === subAccount.name);
        const currencies = countsPerSubaccount.map((it) => {
          return { code: it.currencyCode, id: it.currencyId };
        });
        const currency = currencyId ? currencies.find((it) => it.id === currencyId) : currencies[0];
        if (currency && currencies.length) {
          fetchRows(subAccount, displayNumberOfRows, currency.id);
        } else {
          setRows([]);
        }
        setSelectedSubAccount(subAccount);
        setCurrencies(currencies);
        setSelectedCurrency(currency);
      }
    };

    const updateTable = async (numberOfRows = displayNumberOfRows) => {
      if (numberOfRows !== displayNumberOfRows) {
        setDisplayNumberOfRows(numberOfRows);
      }
      fetchRows(selectedSubAccount, numberOfRows);
    };

    const renderTotalRowCount = useMemo(() => {
      let total = 0;
      if (rowCountsForTable) {
        Object.values(rowCountsForTable).forEach((count) => (total += count));
      }
      return total;
    }, [rowCountsForTable]);

    const renderRowCount = useMemo(() => {
      return rowCountsForTable && selectedSubAccount
        ? selectedSubAccount.name === ALL_SUB_ACCOUNTS.name
          ? renderTotalRowCount
          : rowCountsForTable[selectedSubAccount.id]
        : "";
    }, [selectedSubAccount, renderTotalRowCount, ALL_SUB_ACCOUNTS, rowCountsForTable]);

    const getRowsForRowGroup = async (rowGroupId) => {
      const rows = await recStore.getRowsForRowGroup(teamId, rowGroupId);
      return rows;
    };

    const onClickExpandRows = async (params) => {
      let rows = [];
      if (params.data && params.data.id) {
        const resp = await getRowsForRowGroup(params.data.id);
        rows = formatUnmatchedRowsForTable(resp).rows;
      }
      return rows;
    };

    return (
      <>
        <ManageLayout
          headerTabs={
            <div className="d-flex">
              <SubAccountsDropdown
                selectedSubAccount={selectedSubAccount}
                subAccounts={[ALL_SUB_ACCOUNTS, ...subAccounts]}
                onClick={(subAccount) => {
                  thisIsACashRec ? updateSubAccountForCashRec(subAccount) : fetchRows(subAccount);
                }}
              />
              {thisIsACashRec ? (
                <CurrencyDropdown
                  selectedCurrency={selectedCurrency}
                  currencies={currencies}
                  onClick={(currency) => {
                    fetchRows(selectedSubAccount, displayNumberOfRows, currency.id);
                    fetchRowCounts(currency.id);
                    setSelectedCurrency(currency);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          }
          rightToolbar={
            recStatus === STATUS.PENDING.status ? (
              <PendingRecToolbar teamId={teamId} rejectRec={rejectRec} rejectApproveDisabled={rejectApproveDisabled} approveRec={approveRec} />
            ) : recStatus === STATUS.REPORT_CREATE.status ? (
              <div className="mb-16">
                <AuthWrapper
                  teamId={teamId}
                  allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_SUBMIT, AUTHORITIES.RECS_REC_APPROVE, AUTHORITIES.RECS_REC_REJECT])}
                >
                  <Button
                    size="md"
                    onClick={() => {
                      modalInstance(MODALS.WARNING).show();
                    }}
                    disabled={recStatus !== STATUS.REPORT_CREATE.status}
                    color="danger"
                  >
                    <Text size="sm" weight="medium">
                      Cancel
                    </Text>
                  </Button>
                </AuthWrapper>
                <WarningModal
                  modalId={MODALS.WARNING}
                  title="Are you sure you want to cancel rec approval?"
                  description="This action will cancel any reports currently being generated and return the rec status to open"
                  buttonClick={() => {
                    cancelReport();
                  }}
                  buttonText="Cancel"
                />
              </div>
            ) : (
              ""
            )
          }
        >
          {recStatus === STATUS.REPORT_CREATE.status ? <RunningMatchingRulesPlaceholder text="Approving reconciliation" /> : ""}
          <PendingGlobalMappingsAlert
            teamId={teamId}
            recTypeId={recTypeId}
            accountId={accountId}
            globalMappingId={globalMappingsEnabledForAccount ? globalMapping.id : null}
          />
          <R props="mb-8 pl-0 pr-0 pt-16">
            <C4 props="pl-0">
              <ColumnViewEditor gridRef={gridRef} recTypeId={recTypeId} teamId={teamId} selectedTab="Processed" />
            </C4>
            <C4 props="text-center">
              <RowBatchSelector
                rowCount={renderRowCount}
                displayNumberOfRows={displayNumberOfRows}
                fetchRows={async (option) => {
                  await updateTable(option);
                }}
              />
            </C4>
            <C4 props="text-right pr-0">
              <ToolTip text="Download Table" direction="left" size="large">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    downloadFile();
                  }}
                >
                  <IconDownload className="btn-lg-svg" />
                </span>
              </ToolTip>
            </C4>
          </R>
          <Table
            columnDefs={headers}
            rowData={rows}
            ref={gridRef}
            height="60vh"
            masterDetail={true}
            detailCellRendererParams={{
              detailGridOptions: {
                columnDefs: unmatchedColumns,
              },
              getDetailRowData: async (params) => {
                const rows = await onClickExpandRows(params);
                params.successCallback(rows);
              },
            }}
          />
        </ManageLayout>
        <AnimatedPanelToggleButton open={recDetailsOpen !== null} onClick={() => setRecDetailsOpen(recDetailsOpen !== null ? null : "all")} />
        <RecSidePanel rowGroupDetailsOpen={false} recDetailsOpen={recDetailsOpen !== null} includeRowGroupDetails={false}>
          <RecDetailsPanel
            rec={rec}
            date={new Date(getYearStringFromTimestamp(rec.startDate), getMonthStringFromTimestamp(rec.startDate) - 1, getDayStringFromTimestamp(rec.startDate))}
            recType={recType}
            account={selectedAccount}
            recDetailsOpen={recDetailsOpen}
            setRecDetailsOpen={setRecDetailsOpen}
            subAccounts={subAccounts}
            rowCounts={rowCounts}
            uploadedFiles={uploadedFiles}
            updateUrl={(subAccount, tab, currencyId) => {
              if (thisIsACashRec) {
                updateSubAccountForCashRec(subAccount, currencyId);
                if (currencyId) {
                  fetchRowCounts(currencyId);
                }
              } else {
                fetchRows(subAccount);
              }
            }}
            uiDisabled={true}
          />
        </RecSidePanel>
      </>
    );
  }
);

export { PendingCompletedRecView };
