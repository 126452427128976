import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { Text } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { isUserAuthorized } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { EditableNumber } from "../editable/EditableNumber";

const BalanceToleranceAccountSetup = observer(({ recTypeId, accountId, accountVersion, baseCurrencyCode = "" }) => {
  const { accountStore, rolesStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId");
  const [balanceTolerance, setBalanceTolerance] = useState(0);
  const [localAccountVersion, setLocalAccountVersion] = useState(accountVersion);
  const [initialised, setInitialised] = useState(false);

  const findRecTypeAccountSettings = async () => {
    const resp = await accountStore.findRecTypeAccountSettings(teamId, accountId, recTypeId);
    if (resp.status === 200 && resp.data && ![null, undefined].includes(resp.data.balanceTolerance)) {
      setBalanceTolerance(resp.data.balanceTolerance);
    }
  };

  const saveBalanceTolerance = async (balanceTolerance) => {
    const resp = await accountStore.setBalanceTolerance(teamId, accountId, recTypeId, localAccountVersion, balanceTolerance);
    if (resp.status === 200) {
      setBalanceTolerance(balanceTolerance);
      setLocalAccountVersion(localAccountVersion + 1);
    }
  };

  if (!initialised && recTypeId && accountId) {
    setInitialised(true);
    findRecTypeAccountSettings();
  }
  return (
    <div>
      <Text size="sm" weight="bold">
        Balance tolerance
      </Text>
      <div className="mt-8">
        <Text size="sm" weight="regular">
          Balance tolerance refers to the acceptable difference between two sets of data during the reconciliation process. If the difference between the two
          balances is equal to or falls below this limit, the reconciliation is deemed successful.
        </Text>
      </div>
      <div className="d-flex mt-32 mb-32">
        <div className="mr-32">
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Fund base currency
            </Text>
          </div>
          <Text size="sm" weight="regular">
            {baseCurrencyCode}
          </Text>
        </div>
        <div>
          <div className="pb-16">
            <Text size="sm" weight="medium" variant="secondary">
              Max difference
            </Text>
          </div>
          <EditableNumber
            value={balanceTolerance}
            name=""
            label=""
            onSave={(value) => {
              saveBalanceTolerance(value);
            }}
            warningText=""
            disabled={
              !isUserAuthorized({
                teamId: teamId,
                allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]),
              })
            }
          />
        </div>
      </div>
      <div>
        <Text size="sm" weight="regular" variant="secondary">
          Max difference default is 0. To update select 'Edit', then update the amount and save.
        </Text>
      </div>
      <div>
        <Text size="sm" weight="regular" variant="secondary">
          Base currency can be updated in the fund setup
        </Text>
      </div>
    </div>
  );
});

export { BalanceToleranceAccountSetup };
