import { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Text, Button, EmptyLookups, IconCloseRemove, IconCheck } from "@fundrecs/ui-library";
import { PageTitleArea, ManageLayout, MainContainer } from "../layout/Layout";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { DropdownWithDescription } from "../reusable/DropdownWithDescription";
import { Table } from "../ag-grid/Ag-grid";
import { AUTHORITIES, STATUS } from "../../utils/enums";
import { AuthWrapper } from "../AuthorizationWrapper";
import { StatusBadge } from "../reusable/StatusBadge";
import { DownloadTableButton } from "../reusable/DownloadTableButton";
import { ifNullUndefinedArray } from "../../utils/utils";

/**
 * Global Mappings
 */
const GlobalMappings = observer(({}) => {
  const { recTypeStore, teamStore, accountStore, globalMappingStore, uiStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const recTypeId = searchParams.get("recTypeId") ? Number(searchParams.get("recTypeId")) : null;
  const accountId = searchParams.get("accountId") ? Number(searchParams.get("accountId")) : null;
  const globalMappingId = searchParams.get("globalMappingId") ? Number(searchParams.get("globalMappingId")) : null;
  const clientsAccountsPerRecType = recTypeStore.getClientsAccountsPerRecType();
  const accounts = [];

  clientsAccountsPerRecType.forEach((clientData) => {
    clientData.accountList.forEach((acc) => {
      if (!accounts.filter((it) => it.id === acc.account.id).length) {
        accounts.push(acc.account);
      }
    });
  });

  const recTypes = ifNullUndefinedArray(recTypeStore.getRecTypes());
  const globalMappings = globalMappingStore.getGlobalMappings();
  const globalMapping = globalMappings.length ? globalMappings[0] : null;
  const [recTypeInDropdown, setRecTypeInDropdown] = useState(null);
  const [accountInDropdown, setAccountInDropdown] = useState(null);
  const [selectedRecType, setSelectedRecType] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [columnDefs, setColumnDefs] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [draftMappingEntry, setDraftMappingEntry] = useState(false);
  const USER_CREATED_DRAFT_ROW = "userCreatedDraftRow";

  //Reset page is team is changed
  useEffect(() => {
    setRowData(null);
    setSelectedRecType(null);
    setSelectedAccount(null);
    setRecTypeInDropdown(null);
    setAccountInDropdown(null);
  }, [teamId]);

  const updateTable = (globalMapping, response) => {
    let updatedColumns = null;
    let updatedRows = null;
    if (response.status === 200 && response.data) {
      updatedRows = [];
      updatedColumns = [
        {
          field: "",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          width: 50,
          maxWidth: 50,
        },
        {
          headerName: "status",
          field: "status",
          cellRenderer: (params) => {
            return <StatusBadge status={params.data.status.toUpperCase()} />;
          },
        },
      ];

      updatedColumns.push({
        headerName: `${globalMapping.one.name} ${globalMapping.one.column.displayName} (one)`,
        field: `${globalMapping.one.name} ${globalMapping.one.column.displayName}`,
        editable: (params) => params.data[USER_CREATED_DRAFT_ROW],
        cellStyle: { backgroundColor: "#F3F3F5" },
      });
      if (globalMapping.one.context) {
        updatedColumns.push({
          headerName: `${globalMapping.one.name} ${globalMapping.one.context.displayName}`,
          field: `${globalMapping.one.name} ${globalMapping.one.context.displayName}`,
          editable: (params) => params.data[USER_CREATED_DRAFT_ROW],
        });
      }

      updatedColumns.push({
        headerName: `${globalMapping.many.name} ${globalMapping.many.column.displayName} (many)`,
        field: `${globalMapping.many.name} ${globalMapping.many.column.displayName}`,
        editable: (params) => params.data[USER_CREATED_DRAFT_ROW],
      });
      if (globalMapping.many.context) {
        updatedColumns.push({
          headerName: `${globalMapping.many.name} ${globalMapping.many.context.displayName}`,
          field: `${globalMapping.many.name} ${globalMapping.many.context.displayName}`,
          editable: (params) => params.data[USER_CREATED_DRAFT_ROW],
        });
      }

      if (response.data.length) {
        Object.keys(response.data[0]).forEach((key) => {
          if (!["id", "columnMappings", "status"].includes(key)) {
            updatedColumns.push({ headerName: key, field: key });
          }
        });
        response.data.forEach((mapping) => {
          updatedRows.push({ ...mapping, ...mapping.columnMappings });
        });
      }
    }
    setColumnDefs(updatedColumns);
    setRowData(updatedRows);
  };

  const fetchGlobalMappings = async (teamId, globalMapping, recType, account, replace) => {
    const resp = await globalMappingStore.fetchGlobalMappingValues(teamId, globalMapping.id, account.id);
    updateTable(globalMapping, resp);
    if (resp.status === 200) {
      setSelectedRecType(recType);
      setSelectedAccount(account);
      navigate(`${PATH.GLOBAL_MAPPINGS}?teamId=${teamId}&globalMappingId=${globalMapping.id}&recTypeId=${recType.id}&accountId=${account.id}`, {
        replace: replace,
      });
    }
  };

  const initialiseData = async () => {
    setInitialised(true);
    const recTypes = await recTypeStore.fetchRecTypesForTeam(teamId);
    const accounts = await accountStore.fetchAccountsForTeam(teamId);
    recTypeStore.setSelectedRecType(null);
    if (accountId && globalMappingId && recTypeId) {
      const gmResponse = await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recTypeId);
      if (gmResponse.status === 200 && gmResponse.data.length) {
        await fetchGlobalMappings(
          teamId,
          gmResponse.data[0],
          recTypes.find((it) => it.id === recTypeId),
          accounts.find((it) => it.id === accountId),
          true
        );
      }
    }
  };

  if (!initialised && teamId) {
    initialiseData();
  }

  const handleAddRow = () => {
    setDraftMappingEntry(true);

    let newRow = {};
    columnDefs
      .filter((it) => it.name)
      .forEach((header) => {
        newRow[header.name] = "";
      });
    newRow.status = STATUS.DRAFT.text;
    newRow[USER_CREATED_DRAFT_ROW] = true;
    newRow.id = -1;
    const gridApi = gridRef.current.api;
    gridApi.applyTransactionAsync({ add: [newRow], addIndex: 0 }, () => {
      gridApi.deselectAll();
      gridApi.addRow = true;

      const firstMappingCol = columnDefs.find((it) => ![undefined, "status"].includes(it.headerName));
      const colKey = firstMappingCol ? firstMappingCol.field : "";
      gridApi.setFocusedCell(0, colKey);
      gridApi.startEditingCell({
        rowIndex: 0,
        colKey: colKey,
      });
    });
  };

  const handleClearRow = () => {
    const gridApi = gridRef.current.api;
    const { data } = gridApi.getDisplayedRowAtIndex(0);
    gridApi.applyTransactionAsync({ remove: [data] }, () => {
      gridApi.addRow = false;
      setDraftMappingEntry(false);
    });
  };

  const addMappingValue = async () => {
    const gridApi = gridRef.current.api;
    gridApi.stopEditing();
    const { data } = gridApi.getDisplayedRowAtIndex(0);

    const body = {
      oneSide: data[`${globalMapping.one.name} ${globalMapping.one.column.displayName}`],
      manySide: data[`${globalMapping.many.name} ${globalMapping.many.column.displayName}`],
    };

    if (globalMapping.one.context) {
      body.oneContext = data[`${globalMapping.one.name} ${globalMapping.one.context.displayName}`];
    }
    if (globalMapping.many.context) {
      body.manyContext = data[`${globalMapping.many.name} ${globalMapping.many.context.displayName}`];
    }

    const response = await globalMappingStore.addGlobalMappingValues(teamId, globalMapping.id, selectedAccount.id, body);

    if (response.status === 200) {
      gridApi.addRow = false;
      setDraftMappingEntry(false);
      fetchGlobalMappings(teamId, globalMapping, selectedRecType, selectedAccount, true);
    }
  };

  const approveMappings = async () => {
    const pendingIds = gridRef.current.api
      .getSelectedRows()
      .filter((it) => it.status === STATUS.PENDING.text)
      .map((it) => it.id);
    if (pendingIds.length) {
      const resp = await globalMappingStore.approveGlobalMappingValues(teamId, globalMapping.id, selectedAccount.id, pendingIds);
      if (resp.status === 200) {
        fetchGlobalMappings(teamId, globalMapping, selectedRecType, selectedAccount, true);
      }
    }
  };

  const rejectMappings = async () => {
    const pendingIds = gridRef.current.api
      .getSelectedRows()
      .filter((it) => it.status === STATUS.PENDING.text)
      .map((it) => it.id);
    if (pendingIds.length) {
      const resp = await globalMappingStore.deleteGlobalMappingValues(teamId, globalMapping.id, selectedAccount.id, pendingIds);
      if (resp.status === 200) {
        fetchGlobalMappings(teamId, globalMapping, selectedRecType, selectedAccount, true);
      }
    }
  };

  const RecTypeAndAccountSelector = () => {
    return (
      <>
        <DropdownWithDescription
          description="Reconciliation type: "
          displayValue={recTypeInDropdown ? recTypeInDropdown.name : "Select type "}
          dropdownOptions={recTypes}
          selectOption={async (recType) => {
            await globalMappingStore.fetchGlobalMappingByRecTypeId(teamId, recType.id);
            await recTypeStore.fetchClientsAccountsPerRecType(teamId, recType.id);
            setRecTypeInDropdown(recType);
            setAccountInDropdown(null);
          }}
          width="24rem"
        />
        <span className="mr-16"></span>

        <DropdownWithDescription
          description="Fund: "
          displayValue={accountInDropdown ? accountInDropdown.name : "Select fund "}
          dropdownOptions={accounts}
          selectOption={(account) => {
            setAccountInDropdown(account);
          }}
          width="24rem"
        />
        <span className="mr-16"></span>
        <Button
          size="md"
          onClick={() => {
            if (!globalMapping) {
              uiStore.addNotification("error", `No global mapping setup found for ${recTypeInDropdown.name}`);
            } else {
              fetchGlobalMappings(teamId, globalMapping, recTypeInDropdown, accountInDropdown, false);
            }
          }}
          disabled={recTypeInDropdown === null || accountInDropdown === null}
          color="primary"
        >
          <Text size="sm" weight="regular">
            Load global mappings
          </Text>
        </Button>
      </>
    );
  };

  const createFileName = () => {
    const fileName = `${selectedRecType.name} global mappings for ${selectedAccount.name}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <MainContainer>
      <PageTitleArea title="Global mappings" borderBottom={false} />
      <ManageLayout>
        <>
          {rowData === null ? (
            <div>
              <div style={{ textAlign: "center" }}>
                <EmptyLookups />
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <RecTypeAndAccountSelector />
              </div>
              <div className="d-flex mt-16" style={{ justifyContent: "center" }}>
                <Text weight="regular" size="md">
                  To view available global mappings please select the reconciliation and relevant fund from the list above.
                </Text>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex mt-16 mb-16">
                <RecTypeAndAccountSelector />
                <span className="ml-16">
                  <Text weight="regular" size="sm">
                    To view global mappings, please select the reconciliation type and relevant fund.
                  </Text>
                </span>
              </div>

              <div style={{ borderTop: "1px solid #E6E7EB", padding: "0px", marginLeft: "12px", paddingTop: "16px" }}>
                <Text weight="regular" size="sm">
                  Displaying global mappings for:
                </Text>
                <Text weight="medium" size="sm">{`   ${selectedAccount ? selectedAccount.name : ""}`}</Text>
                <Text weight="regular" size="sm">{`   ${selectedRecType ? selectedRecType.name : ""}`}</Text>
              </div>

              <div className="d-flex justify-content-between mb-8">
                <div></div>
                <div>
                  {draftMappingEntry ? (
                    <>
                      <Button color="primary-secondary" onClick={handleClearRow}>
                        <IconCloseRemove className="btn-md-svg" />
                        <Text size="sm">Clear row</Text>
                      </Button>
                      <span className="ml-8"></span>
                      <Button color="success-primary" onClick={addMappingValue}>
                        <Text size="sm">Save row</Text>
                      </Button>
                    </>
                  ) : (
                    <>
                      <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
                      <span className="mr-16"></span>
                      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_REJECT])}>
                        <Button color="danger-secondary" onClick={rejectMappings}>
                          <IconCloseRemove className="btn-md-svg" />
                          <Text size="sm">Reject rows</Text>
                        </Button>
                      </AuthWrapper>
                      <span className="ml-8"></span>
                      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_APPROVE])}>
                        <Button color="success-secondary" onClick={approveMappings}>
                          <IconCheck className="btn-md-svg" />
                          <Text size="sm">Approve rows</Text>
                        </Button>
                      </AuthWrapper>
                      <span className="ml-8"></span>
                      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_GLOBAL_MAPPING_ENTRY_CREATE])}>
                        <Button onClick={handleAddRow}>
                          <Text size="sm">Add row</Text>
                        </Button>
                      </AuthWrapper>
                    </>
                  )}
                </div>
              </div>
              <Table columnDefs={columnDefs} rowData={rowData} ref={gridRef} rowSelection={"multiple"} />
            </>
          )}
        </>
      </ManageLayout>
    </MainContainer>
  );
});

export { GlobalMappings };
