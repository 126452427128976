import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Text } from "@fundrecs/ui-library";
import { Table } from "../../ag-grid/Ag-grid";
import { AccordianPanel } from "../../reusable/AccordianPanel/AccordianPanel";

const CashRecBalanceTable = observer(({ balanceTableData }) => {
  const subAccountTableRef = useRef(null);
  let rows = balanceTableData && balanceTableData.balance ? balanceTableData.balance.map((it) => it.data) : [];

  const cols =
    balanceTableData && balanceTableData.schema
      ? balanceTableData.schema.map((it) => {
          return { field: it.name };
        })
      : [];

  return (
    <AccordianPanel
      title={<Text>Reconciliations by sub account and currency</Text>}
      openDefault={false}
      elementId="sub-account-table-accordian-body"
      alignIcon="left"
      style={{ marginBottom: "8px", borderBottom: "1px solid #E6E8EB", borderTop: "1px solid #E6E8EB" }}
    >
      <div className="pt-16 pl-32 pr-32">
        <Table columnDefs={cols} rowData={rows} ref={subAccountTableRef} height={400} sizeToFit={true} />
      </div>
    </AccordianPanel>
  );
});

export { CashRecBalanceTable };
