import { observer } from "mobx-react-lite";
import { SubAccountSection } from "./SubAccountSection";
import { CalendarSection } from "./CalendarSection";
import { UploadedFilesSection } from "./UploadedFilesSection";
import { STATUS } from "../../../../utils/enums";

const RecDetailsPanel = observer(
  ({ rec, date, recType, account, recDetailsOpen, setRecDetailsOpen, subAccounts, rowCounts, uploadedFiles, updateUrl, uiDisabled }) => {
    return (
      <div id="recDetailsPanel" className="side-panel-style">
        <SubAccountSection
          panelOpen={recDetailsOpen}
          openOnClick={() => {
            setRecDetailsOpen("subAccounts");
          }}
          allSubAccounts={subAccounts}
          rowCounts={rowCounts}
          updateUrl={updateUrl}
          thisIsACashRec={rec.cashRec}
        />
        <CalendarSection
          rec={rec}
          date={date}
          recType={recType}
          account={account}
          panelOpen={recDetailsOpen}
          openOnClick={() => {
            setRecDetailsOpen("calendar");
          }}
        />
        <UploadedFilesSection
          panelOpen={recDetailsOpen}
          openOnClick={() => {
            setRecDetailsOpen("uploads");
          }}
          uploadedFiles={uploadedFiles}
          uiDisabled={uiDisabled && rec.fourEyes.status !== STATUS.RESTRICTED.status}
        />
      </div>
    );
  }
);

export { RecDetailsPanel };
