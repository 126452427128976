import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../store/Store";
import { SidePanelSection } from "./SidePanelSection";
import { ReactComponent as CalendarIcon } from "../../../../icons/calendar.svg";
import { RecsCalendar } from "../../../reusable/Calendar/RecsCalendar";
import { RecOptions } from "../../../recType/RecTypeDashboard/RecOptions";
import { getDateStringFromTimestamp, getFirstDayOfPreviousMonth, stringifyDate } from "../../../../utils/dates";

const CalendarSection = observer(({ rec, date, recType, account, panelOpen, openOnClick }) => {
  const { recStore, teamStore } = useStore();

  const [initialised, setInitialised] = useState(false);
  const [selectedRec, setSelectedRec] = useState(rec);
  const [selectedDate, setSelectedDate] = useState(null);
  const [recList, setRecList] = useState([]);

  //Change the selected rec in the panel
  const selectRec = (date) => {
    const findRec = recList.find((it) => getDateStringFromTimestamp(it["startDate"]) === stringifyDate(date));
    setSelectedRec(findRec ? findRec : null);
    setSelectedDate(date);
  };

  //Update the list of recs for the month and select the one on recdate
  const updateCalendar = async (initialDate, accountUuid, recDate = null) => {
    const startDate = getFirstDayOfPreviousMonth(initialDate);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

    let data = {
      dateFrom: stringifyDate(startDate),
      dateTill: stringifyDate(endDate),
      accountList: [accountUuid],
      recTypeId: recType.id,
    };
    const updatedRecList = await recStore.getRecsInDateRange(teamStore.getSelectedTeam().id, data);
    let recs = updatedRecList.map((accountAndRec) => accountAndRec.rec);
    recs = recs.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    setRecList(recs);

    //Update selected rec when changing fund, or clicking 'today'
    if (recDate) {
      selectRec(recDate, accountUuid, recs);
    }
  };

  if (!initialised && rec && date && account) {
    setInitialised(true);
    updateCalendar(date, account.uuid);
  }
  return (
    <SidePanelSection
      expandTo="44rem"
      panelOpen={panelOpen}
      title="Calendar"
      openOnClick={openOnClick}
      icon={<CalendarIcon />}
      accordianId={"side-panel-calendar-accordian"}
      sectionOpen={panelOpen === "calendar"}
    >
      <RecsCalendar
        selectRec={selectRec}
        selectedDate={selectedDate ? selectedDate : date}
        selectedAccount={account}
        recList={recList}
        updateCalendar={updateCalendar}
      />
      <div className="pl-24 pr-24 pt-24 pb-24">
        <RecOptions recType={recType} selectedRec={selectedRec} selectedAccount={account} selectedDate={selectedDate} viewOnly={true} />
      </div>
    </SidePanelSection>
  );
});

export { CalendarSection };
