import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Text, R, C2, C8, C10, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store.js";
import { ExpandPanelIcon } from "../../ag-grid/toggleFullWidthRow.js";
import { Table } from "../../ag-grid/Ag-grid.js";
import { VerticalMenu } from "../../reusable/VerticalMenu/VerticalMenu.js";
import { getDayAndTimeString } from "../../../utils/dates.js";
import { DeleteModal } from "../../DeleteModal.js";
import { AUTHORITIES, MODALS, STATUS } from "../../../utils/enums.js";
import { BuildFunctionDescription } from "../../rules/FunctionEditor.js";
import { CreateMatchingRule } from "./CreateMatchingRule.js";
import { formatMatchingRuleToEdit } from "../../rules/dataTransform.js";
import { PATH } from "../../../utils/urls.js";
import { isUserAuthorized } from "../../AuthorizationWrapper.js";
import { StatusBadge } from "../../reusable/StatusBadge.js";
import { FullWidthCellRenderer } from "../../ag-grid/FullWidthCellRenderer.js";

const MatchingRulesList = observer(({}) => {
  const { recTypeStore, teamStore, matchingRuleStore, comparatorFunctionStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const teamId = searchParams.get("teamId");
  const recTypeId = searchParams.get("recType");
  const matchingRules = recTypeStore.getMatchingRulesForRecType();
  const [selectedRule, setSelectedRule] = useState(null);
  const matchingRuleJson = recTypeStore.getMatchingRuleDetails();
  const [initialised, setInitialised] = useState(false);
  const tmos = recTypeStore.getRecTypeTmos();
  const selectedRecType = recTypeStore.getSelectedRecType();
  const comparatorFunctions = comparatorFunctionStore.getFunctions();

  const deleteMatchingRule = async (matchingRuleId, version) => {
    const response = await matchingRuleStore.deleteMatchingRule(teamId, matchingRuleId, version);
    if (response.status === 200) {
      modalInstance(MODALS.DELETE).hide();
      recTypeStore.fetchMatchingRuleDetails(teamId, recTypeId);
    }
  };

  const onItemClick = (option, optionData) => {
    const matchingRule = matchingRuleJson.find((it) => it.id === optionData.id);

    const openModal = (modal) => {
      setSelectedRule({ ...optionData, matchingRule: matchingRule, tmos: tmos });
      modalInstance(modal).show();
    };

    const options = {
      clone: () => {
        openModal(MODALS.CREATE_MATCHING_RULE);
      },
      delete: () => {
        openModal(MODALS.DELETE);
      },
    };
    options[option]();
  };

  const getMenuItems = ({ teamId, data }) => {
    const cloneEnabled =
      data.status === STATUS.PUBLISHED.status &&
      isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_CREATE]) });
    const deleteEnabled = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_DELETE]) });

    return [
      {
        key: "open",
        label: "Open rule",
        disabled: false,
        visible: true,
        link: `${PATH.MATCHING_RULE_BUILDER}?teamId=${teamId}&recTypeId=${recTypeId}&matchingRuleId=${data.id}`,
      },
      { key: "clone", label: "Clone", disabled: !cloneEnabled, visible: cloneEnabled, optionData: data },
      { key: "delete", label: "Delete", disabled: !deleteEnabled, visible: deleteEnabled, optionData: data },
    ];
  };

  /**
   * Updates the panel height by 25px per line
   * Adds additional height for text and tags inputs based on the length/ number of text/ tags
   */
  const updatePanelHeight = (height, filterParams = []) => {
    height += 25;
    const lengthyParams = [...filterParams.filter((it) => it.type === "constant"), ...filterParams.filter((it) => it.type === "notes")];
    if (lengthyParams.length) {
      lengthyParams.forEach((param) => {
        height += 25 * (param.value.length / 50);
      });
    }
    return height;
  };

  const generateMatchingRuleDisplay = (matchingRule, tmos, createdAt, fundList, height) => {
    //const comparatorFunctions = comparatorFunctionStore.getFunctions();
    let line = 25;
    const matchingRuleDisplay = (
      <>
        {!matchingRule ? (
          ""
        ) : (
          <div>
            <R>
              <C10>
                {!matchingRule.filterStep
                  ? ""
                  : `For any${Object.keys(matchingRule.filterStep.filterOperations).map((tmoId, index) => {
                      const tmo = tmos.find((it) => it.id === Number(tmoId));
                      return tmo ? ` ${tmo.name}` : "";
                    })} data available`}
              </C10>
            </R>
            {!matchingRule.filterStep ? (
              ""
            ) : (
              <>
                {Object.entries(matchingRule.filterStep.filterOperations).map((keyValue, index) => {
                  const tmoId = keyValue[0];
                  const filterOperations = keyValue[1];
                  const tmo = tmos.find((it) => it.id === Number(tmoId));
                  return (
                    <>
                      {filterOperations.map((filter, index2) => {
                        const comparatorFunction = comparatorFunctions.find((it) => it.function === filter.function);
                        height = updatePanelHeight(height, filter.params);
                        return (
                          <R>
                            <C10>{comparatorFunction ? BuildFunctionDescription(index + index2, tmo, filter, comparatorFunction.description) : ""}</C10>
                          </R>
                        );
                      })}
                    </>
                  );
                })}

                <R>
                  <C10>{matchingRule.filterStep.proceedAsRows ? "Proceed as individual rows" : "Proceed as a group"}</C10>
                </R>
                {!matchingRule.filterStep.groupBy
                  ? ""
                  : matchingRule.filterStep.groupBy.groupings.map((grouping, groupingIndex) => {
                      const groupingColumnData = Object.entries(grouping);
                      height = updatePanelHeight(height);
                      return (
                        <R>
                          <C10>
                            {groupingIndex === 0 ? "Group when " : "and "}
                            {groupingColumnData.map((keyValue, index) => {
                              const tmo = tmos.find((it) => it.id === Number(keyValue[0]));
                              const groupingColumn = keyValue[1];
                              return (
                                <>
                                  <Text weight="bold" size="sm">{`${tmo ? tmo.name : "unknown"} ${groupingColumn ? groupingColumn.name : "unknown"}`}</Text>
                                  {groupingColumnData.length > index + 1 ? " is equal to " : ""}
                                </>
                              );
                            })}
                          </C10>
                        </R>
                      );
                    })}

                <R props="mb-16">
                  <C10>
                    Use these flattening rules:{" "}
                    {!matchingRule.filterStep.aggregatorSet
                      ? ""
                      : matchingRule.filterStep.aggregatorSet.map((aggregator, index) => {
                          return `${index > 0 ? ", " : ""}${aggregator.name}`;
                        })}
                  </C10>
                </R>
              </>
            )}

            {matchingRule.tagSteps.map((tagStep) => {
              height += line * (tagStep.conditions.length + 1);
              return (
                <>
                  {tagStep.conditions.map((filter, index2) => {
                    const comparatorFunction = comparatorFunctions.find((it) => it.function === filter.function);
                    return (
                      <R>
                        <C10>{comparatorFunction ? BuildFunctionDescription(index2, { name: "Results" }, filter, comparatorFunction.description) : ""}</C10>
                      </R>
                    );
                  })}
                  {tagStep.note ? (
                    <R>
                      <C10>{`${tagStep.note.noteType === "CATEGORY" ? "Then assign to " : "Then tag as "} ${tagStep.note.text}`}</C10>
                    </R>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        )}
        <R props="mt-16">
          <C2 props="pl-8">
            <R>
              <Text weight="bold" size="xs">
                Created:
              </Text>
            </R>
            <R>
              <Text weight="regular" size="xs">
                {createdAt}
              </Text>
            </R>
          </C2>
          <C10>
            <R>
              <Text weight="bold" size="xs">
                Enabled for the following funds:
              </Text>
            </R>
            {fundList
              ? fundList.map((it) => {
                  height += 15;
                  return (
                    <R>
                      <Text weight="regular" size="xs">
                        {it.name}
                      </Text>
                    </R>
                  );
                })
              : ""}
          </C10>
        </R>
      </>
    );

    return { display: matchingRuleDisplay, height: height };
  };

  const fullWidthCellRenderer = (rowNode) => {
    const matchingRule = rowNode.data.fullWidthData.matchingRule;
    const fundList = rowNode.data.fullWidthData.fundList;
    const createdAt = rowNode.data.fullWidthData.createdAt;
    const tmos = rowNode.data.fullWidthData.tmos;
    let height = 200;
    const matchingRuleDisplay = generateMatchingRuleDisplay(matchingRule, tmos, createdAt, fundList, height);
    return (
      <FullWidthCellRenderer rowNode={rowNode} rowHeight={matchingRuleDisplay.height} gridRef={gridRef}>
        <div
          style={{
            padding: "16px",
            background: "#fff",
            lineHeight: "1.5rem",
          }}
        >
          {matchingRuleDisplay["display"]}
        </div>
      </FullWidthCellRenderer>
    );
  };

  const onClickExpandRows = (params) => {
    const matchingRule = matchingRuleJson.length && params.data && params.data.id ? matchingRuleJson.find((it) => it.id === params.data.id) : null;
    return { ...params.data, matchingRule: matchingRule, tmos: tmos };
  };

  const cols = [
    {
      headerName: "Status",
      field: "status",
      maxWidth: 120,
      width: 120,
      cellRenderer: (params) => {
        return <StatusBadge status={params.data.status} />;
      },
    },
    {
      field: "",
      sortable: false,
      filter: false,
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return ExpandPanelIcon(params, onClickExpandRows, gridRef);
      },
    },
    { headerName: "Name", field: "name" },
    {
      field: "",
      sortable: false,
      filter: false,
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />;
      },
    },
  ];

  const populateTable = () => {
    return matchingRules.map((rule) => {
      return {
        id: rule.id,
        name: rule.name,
        status: rule.state.status,
        description: rule.description,
        createdAt: getDayAndTimeString(new Date(rule.createdBy.timeStamp)),
        fundList: rule.fundList,
        version: rule.version,
      };
    });
  };

  //Duplicated from DvRuleList.js
  if (!initialised && selectedRecType) {
    recTypeStore.fetchMatchingRuleDetails(searchParams.get("teamId"), recTypeId);
    setInitialised(true);
  }

  return (
    <>
      <Table
        rowSelection="single"
        columnDefs={cols}
        rowData={matchingRules === null ? null : populateTable()}
        ref={gridRef}
        colFlex={true}
        fullWidthCellRenderer={fullWidthCellRenderer}
      />
      <DeleteModal
        title="Are you sure you want to delete this rule?"
        onDelete={() => {
          deleteMatchingRule(selectedRule.id, selectedRule.version);
        }}
        onClose={() => {
          setSelectedRule(null);
        }}
      >
        <R props="ml-8 mr-8">
          <C2></C2>
          <C8>
            <Text weight="bold" size="sm">
              {selectedRule ? selectedRule.name : ""}
            </Text>
          </C8>
        </R>
        <R props="ml-8 mr-8 mb-16">
          <C2></C2>
          <C8>
            <Text weight="regular" size="sm">
              {selectedRule ? selectedRule.description : ""}
            </Text>
          </C8>
        </R>
        <R props="mb-16">
          <C2></C2>
          <C8>
            <Text weight="regular" size="sm">
              This action will mean this rule will no longer be available to funds with this reconciliation type enabled. This action is permanent and cannot be
              reversed.
            </Text>
          </C8>
        </R>
        <R props="ml-8 mr-8">
          <C2></C2>
          <C8>
            <Text weight="bold" size="sm">
              {selectedRule
                ? `This rule is currently in use by ${
                    selectedRule.fundList.length === 1 ? selectedRule.fundList.length + " fund" : selectedRule.fundList.length + " funds"
                  }`
                : ""}
            </Text>
          </C8>
        </R>
        <R></R>
        <R props="mb-16">
          <C2></C2>
          <C8>
            <Text weight="regular" size="sm">
              This action will also remove the rule from these funds permanently.
            </Text>
          </C8>
        </R>
        <R props="mb-16">
          <C2></C2>
          <C8>
            <Text weight="regular" size="sm">
              If you wish to remove the rule from a single fund, simply disable the rule at the fund level.
            </Text>
          </C8>
        </R>
      </DeleteModal>
      <CreateMatchingRule
        selectedRule={selectedRule}
        deselectRule={() => {
          setSelectedRule(null);
        }}
      />
    </>
  );
});

export { MatchingRulesList };
