import { IconDownArrow, DropdownList, DropdownListItem, DropdownItem, ItemBody } from "@fundrecs/ui-library";
import "./toolbarButtons.css";

const SubAccountsDropdown = ({ selectedSubAccount, onClick, subAccounts, disabled = false }) => {
  return (
    <div className="dropdown mb-16" style={{ width: "300px" }}>
      <div data-bs-toggle="dropdown" className="dropdown-button-container ellipsize-text">
        <span>{selectedSubAccount ? selectedSubAccount.name : ""}</span>
        <IconDownArrow className="btn-sm-svg dropdown-active-icon" />
      </div>
      <DropdownList>
        {subAccounts.map((subAccount, index) => {
          return (
            <DropdownListItem
              onClick={() => {
                onClick(subAccount);
              }}
              key={index + 1}
            >
              <DropdownItem active={null} index={0}>
                <ItemBody title={subAccount.name}>{subAccount.name}</ItemBody>
              </DropdownItem>
            </DropdownListItem>
          );
        })}
      </DropdownList>
    </div>
  );
};

export { SubAccountsDropdown };
