import { useState, useEffect } from "react";
import { R, C, C8, C4, Input, Text, SaveEditable, Button } from "@fundrecs/ui-library";

const EditableNumber = ({ name, label, value, onSave, onChange, warningText, disabled }) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <R props="pl-0">
      {label ? (
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            {label}
          </Text>
        </C4>
      ) : (
        ""
      )}
      {true ? (
        <>
          <C8 props="pl-0 pr-0">
            <Input
              disabled={!editing}
              type="number"
              name={name}
              value={fieldValue}
              onChange={(event) => {
                setFieldValue(event.target.value);

                if (onChange) {
                  onChange(event.target.value);
                }
              }}
              warning={Boolean(warningText)}
              warningMessage={warningText}
            />
          </C8>
        </>
      ) : (
        <></>
      )}

      {editing ? (
        <C props="pt-4 pb-4">
          <SaveEditable
            onClickSave={() => {
              onSave(fieldValue);
              setEditing(false);
            }}
            onClickCancel={() => {
              setFieldValue(value);
              setEditing(false);
            }}
          />
        </C>
      ) : (
        <>
          {disabled ? (
            ""
          ) : (
            <C>
              <Button
                onClick={() => {
                  setEditing(true);
                  setFieldValue(value);
                }}
              >
                <Text weight="regular">Edit</Text>
              </Button>
            </C>
          )}
        </>
      )}
    </R>
  );
};

export { EditableNumber };
