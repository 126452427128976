const stringifyDate = (dateObject, separator = null) => {
  if (!(dateObject instanceof Date)) {
    return "";
  }
  const date = prefixZero(dateObject.getDate());
  const month = prefixZero(dateObject.getMonth() + 1);
  if (separator === null) {
    separator = "-";
  }
  return `${dateObject.getFullYear()}${separator}${month}${separator}${date}`;
};

const stringifyDateTimeISO8601 = (dateObject) => {
  if (!(dateObject instanceof Date)) {
    return "";
  }
  const date = prefixZero(dateObject.getDate());
  const month = prefixZero(dateObject.getMonth() + 1);
  const hours = prefixZero(dateObject.getHours());
  const minutes = prefixZero(dateObject.getMinutes());
  const seconds = prefixZero(dateObject.getSeconds());
  const miliSeconds = prefixZeroMiliseconds(dateObject.getMilliseconds());
  const separator = "-";

  return `${dateObject.getFullYear()}${separator}${month}${separator}${date}T${hours}:${minutes}:${seconds}.${miliSeconds}Z`;
};

const getDayString = (dateObject) => {
  if (!dateObject instanceof Date) {
    return "";
  }
  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  return dateObject.toLocaleDateString("en-GB", options);
};

const prefixZero = (number) => {
  if (number < 10) {
    number = `0${number}`;
  }
  return `${number}`;
};

const prefixZeroMiliseconds = (number) => {
  let numberString = `${number}`;
  if (number < 100) {
    numberString = `0${number}`;
  }
  if (number < 10) {
    numberString = `0${numberString}`;
  }
  return `${numberString}`;
};

const nextDate = (date) => {
  if (!date instanceof Date) {
    return date;
  }
  let next = new Date(date);
  next.setDate(date.getDate() + 1);
  return next;
};

const previousDate = (date) => {
  if (!date instanceof Date) {
    return date;
  }
  let previous = new Date(date);
  previous.setDate(date.getDate() - 1);
  return previous;
};

const getTodayMinusOne_Mon_Fri = (date) => {
  const previous = previousDate(date);
  if ([0, 6].includes(previous.getDay())) {
    return getTodayMinusOne_Mon_Fri(previous);
  } else {
    return previous;
  }
};

const getFirstDayOfPreviousMonth = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth() - 1, 1);
};

const getFirstDayOfMonth = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
};

const getDayAndTimeString = (dateObj) => {
  const date = getDayString(dateObj);
  return `${date} ${prefixZero(dateObj.getHours())}:${prefixZero(dateObj.getMinutes())}:${prefixZero(dateObj.getSeconds())}`;
};

const getTimeFromDate = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

//dateString must be in YYYY-MM-dd
const convertToDate = (dateString) => {
  const year = Number(dateString.substring(0, 4));
  const month = Number(dateString.substring(5, 7)) - 1;
  const day = dateString.substring(8);
  return new Date(year, month, day);
};

const getDateStringFromTimestamp = (timeStamp) => {
  return timeStamp.substring(0, timeStamp.indexOf("T"));
};

const getYearStringFromTimestamp = (timeStamp) => {
  return getDateStringFromTimestamp(timeStamp).slice(0, 4);
};

const getMonthStringFromTimestamp = (timeStamp) => {
  return getDateStringFromTimestamp(timeStamp).slice(5, 7);
};

const getDayStringFromTimestamp = (timeStamp) => {
  return getDateStringFromTimestamp(timeStamp).slice(8, 10);
};

//Audit Trail saves dates differently, rather than a timestamp, it returns dates in 'dd/MM/yyyy HH:MM:SS'
//These are saved in UTC
const getDateForAuditTrail = (dateString) => {
  const year = Number(dateString.substring(6, 10));
  const month = Number(dateString.substring(3, 5)) - 1;
  const day = dateString.substring(0, 2);
  const hour = dateString.substring(11, 13);
  const minutes = dateString.substring(14, 16);
  const seconds = dateString.substring(17, 19);
  return new Date(Date.UTC(year, month, day, hour, minutes, seconds));
};

export {
  stringifyDate,
  getDayString,
  nextDate,
  previousDate,
  stringifyDateTimeISO8601,
  getFirstDayOfMonth,
  getDayAndTimeString,
  getTodayMinusOne_Mon_Fri,
  getTimeFromDate,
  convertToDate,
  getDateStringFromTimestamp,
  getYearStringFromTimestamp,
  getMonthStringFromTimestamp,
  getDayStringFromTimestamp,
  getDateForAuditTrail,
  getFirstDayOfPreviousMonth,
};
