import { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Text, Button, modalInstance } from "@fundrecs/ui-library";
import { PageTitleArea, ManageLayout, MainContainer } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { AUTHORITIES, MODALS, STATUS } from "../../utils/enums";
import { AuthWrapper } from "../AuthorizationWrapper";
import { ManualFileUploadModal } from "../uploads/ManualFileUploadModal";
import { DownloadTableButton } from "../reusable/DownloadTableButton";
import { getTodayMinusOne_Mon_Fri } from "../../utils/dates";
import { RecModeIcon } from "../reusable/RecModeIcon";

const ReconciliationTypes = observer(({}) => {
  const { recTypeStore, teamStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const recTypes = recTypeStore.getRecTypes();

  const populateTable = () => {
    let rows = [];
    recTypes.forEach((recType) => {
      if (recType.status === STATUS.APPROVED.status) {
        const recTypeType = recType.setting && recType.setting.settingFor === "REC_TYPE_TYPE" ? recType.setting.value : "";
        rows.push({ id: recType.id, name: recType.name, description: recType.description, status: recType.status, type: recTypeType });
      }
    });
    return rows;
  };

  useEffect(() => {
    if (!initialised && teamId && recTypes !== null) {
      navigate(`${PATH.REC_TYPES}?teamId=${teamId}`, { replace: true });
      recTypeStore.setSelectedRecType(null);
      setInitialised(true);
    }
  });

  const cols = [
    {
      headerName: "Mode",
      field: "type",
      maxWidth: 100,
      cellRenderer: (params) => {
        return <RecModeIcon type={params.data.type} className="btn-lg-svg" />;
      },
    },
    {
      headerName: "Name",
      field: "name",
      cellRenderer: (params) => {
        return (
          <Link className="text-decoration-none" to={`${PATH.REC_TYPE_DASH}?teamId=${teamId}&recType=${params.data.id}`}>
            <Text variant="link" size="xs">
              {params.data.name}
            </Text>
          </Link>
        );
      },
    },
    { headerName: "Description", field: "description" },
  ];

  const createFileName = () => {
    const fileName = `All reconciliation types for ${teamStore.getSelectedTeam().name}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    // PageTitle Area props = <CreateRecTypePanel />
    <MainContainer>
      <PageTitleArea
        title="Dashboard"
        description={<div className="text-medium pb-4">View your teams reconciliation types</div>}
        borderBottom={true}
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_REC_UPLOAD_FILE])}>
              <Button
                size="md"
                onClick={() => {
                  modalInstance(MODALS.MANUAL_UPLOAD).show();
                }}
                disabled={false}
                color="primary-secondary"
              >
                <Text size="sm" weight="medium">
                  Upload a file
                </Text>
              </Button>
            </AuthWrapper>
            <span className="ml-16"></span>
            <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
          </>
        }
      />
      <ManageLayout>
        <div className="mt-32"></div>
        <Table rowSelection="single" columnDefs={cols} rowData={recTypes === null ? null : populateTable()} ref={gridRef} colFlex={true} />
        {/*<RecTypeSetup />*/}
        <ManualFileUploadModal teamId={teamId} selectedDate={getTodayMinusOne_Mon_Fri(new Date())} />
      </ManageLayout>
    </MainContainer>
  );
});

export { ReconciliationTypes };
