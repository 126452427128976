import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const rec = {
  getRecById: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}`),
  getRecsInDateRange: (teamId, data) => fusionRequest.post(`team/${teamId}/rec`, data),
  getUnmatchedRows: (teamId, recId, subAccountUuid, recVersion, pageNo = 0, currencyId = null) =>
    fusionRequest.get(
      `team/${teamId}/rec/${recId}/unmatched?subAccount=${subAccountUuid}&version=${recVersion}&pageNo=${pageNo}${
        currencyId ? "&currencyId=" + currencyId : ""
      }`
    ),
  runMatchingRule: (teamId, recId, version, matchingRuleId, data) =>
    fusionRequest.post(`team/${teamId}/rec/${recId}/group/${matchingRuleId}?version=${version}`, data),
  runAllAutomatchRules: (teamId, recId, version, subAccountId) =>
    fusionRequest.post(`team/${teamId}/rec/${recId}/subAccount/${subAccountId}/group?version=${version}`),
  pollRecStatus: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/status`),
  removeMatch: (teamId, recId, subAccountUuid, version, data) =>
    fusionRequest.delete(`team/${teamId}/rec/${recId}/subAccount/${subAccountUuid}/group?version=${version}`, data),
  submitRec: (teamId, recId, version) => fusionRequest.post(`team/${teamId}/rec/${recId}/submit?version=${version}`),
  rejectRec: (teamId, recId, version) => fusionRequest.post(`team/${teamId}/rec/${recId}/reject?version=${version}`),
  approveRec: (teamId, recId, version) => fusionRequest.post(`team/${teamId}/rec/${recId}/approve?version=${version}`),
  reopenAdHocRec: (teamId, recId, version) => fusionRequest.post(`team/${teamId}/rec/${recId}/draft?version=${version}`),
  reopenSequentialRec: (teamId, body) => fusionRequest.post(`team/${teamId}/rec/reOpen`, body),
  cancelReport: (teamId, recId, version) => fusionRequest.post(`team/${teamId}/rec/${recId}/cancelReport?version=${version}`),
  getUploadedFilesForRec: (teamId, recId) => fusionRequest.get(`team/${teamId}/file/rec/${recId}`),
  getRowGroupsForSubAccount: (teamId, recId, subAccountUuid, recVersion, pageNo, currencyId) =>
    fusionRequest.get(
      `team/${teamId}/rec/${recId}/groups/${subAccountUuid}?version=${recVersion}&pageNo=${pageNo}${currencyId ? "&currencyId=" + currencyId : ""}`
    ),
  getRowGroupsForAllSubAccounts: (teamId, recId, recVersion, pageNo, currencyId) =>
    fusionRequest.get(
      `team/${teamId}/rec/${recId}/groupsForAllSubAccounts?version=${recVersion}&pageNo=${pageNo}${currencyId ? "&currencyId=" + currencyId : ""}`
    ),
  saveNotesToRowGroup: (teamId, groupId, version, data) => fusionRequest.post(`team/${teamId}/group/${groupId}/notes?version=${version}`, data),
  saveCommentToRowGroups: (teamId, body) => fusionRequest.post(`team/${teamId}/group/comment/multiple-groups`, body),
  removeTagFromRowGroup: (teamId, groupId, noteId, version) => fusionRequest.delete(`team/${teamId}/group/${groupId}/note/${noteId}?version=${version}`),
  getRowsForRowGroup: (teamId, groupId) => fusionRequest.get(`team/${teamId}/group/${groupId}/rows`),
  canSubmit: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/canSubmit`),
  manuallyCreateRowGroup: (teamId, recId, version, body) => fusionRequest.post(`team/${teamId}/rec/${recId}/group?version=${version}`, body),
  fetchRowCounts: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/subAccount/countProcessedAndUnprocessedRows`),
  fetchRowCountsPerCurrency: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/subAccount/countProcessedAndUnprocessedRowsPerCurrency`),
  fetchRowCountForTable: (teamId, recId, subAccountId, currencyId) =>
    fusionRequest.get(
      `team/${teamId}/rec/${recId}/subAccount/${subAccountId}/countUnprocessedRowsAndRowGroups${currencyId ? "?currencyId=" + currencyId : ""}`
    ),
  fetchRecAudit: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/audit`),
  rowCountPerCategory: (teamId, recId) => fusionRequest.get(`team/${teamId}/rec/${recId}/subAccount/countRowsByCategory`),
  setAsDayOne: (teamId, body) => fusionRequest.post(`team/${teamId}/rec/setAsDayOne`, body),
  skipToDate: (teamId, body) => fusionRequest.post(`team/${teamId}/rec/skipToDate`, body),
};

export { rec };
