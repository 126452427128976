import { fusionRequest } from "../fusionRequest";

const account = {
  fetchAccountById: (teamId, accountId) => fusionRequest.get(`team/${teamId}/account/${accountId}`),
  fetchAccountsForTeam: (teamId) => fusionRequest.get(`team/${teamId}/account/dashboard`),
  fetchDefaultAccountSettings: (teamId) => fusionRequest.get(`team/${teamId}/account/setting`),
  createAccount: (teamId, clientId, body) => fusionRequest.post(`team/${teamId}/client/${clientId}/account`, body),
  fetchEnabledRecTypesForAccount: (teamId, accountId) => fusionRequest.get(`team/${teamId}/account/${accountId}/recTypes`),
  fetchRecTypesSubAccountsAliases: (teamId, accountId) => fusionRequest.get(`team/${teamId}/account/${accountId}/recTypesWithSubs`),
  fetchRecTypesWithAliases: (teamId, accountId) => fusionRequest.get(`team/${teamId}/account/${accountId}/recTypesWithAliases`),
  updateAccount: (teamId, clientId, accountId, version, body) =>
    fusionRequest.put(`team/${teamId}/client/${clientId}/account/${accountId}?version=${version}`, body),
  setClient: (teamId, clientId, accountId, version) => fusionRequest.put(`team/${teamId}/account/${accountId}/client/${clientId}?version=${version}`),
  updateSetting: (teamId, clientId, accountId, version, body) =>
    fusionRequest.put(`team/${teamId}/client/${clientId}/account/${accountId}/setting?version=${version}`, body),
  updateCurrency: (teamId, clientId, accountId, currencyId, version) =>
    fusionRequest.put(`team/${teamId}/client/${clientId}/account/${accountId}/currency/${currencyId}?version=${version}`),
  setAutoCompleteRule: (teamId, accountId, acId, version, recTypeId) =>
    fusionRequest.put(`team/${teamId}/account/${accountId}/autoComplete/${acId}/enable?version=${version}&recTypeId=${recTypeId}`),
  setAutoCompleteRuleSchedule: (teamId, accountId, acId, version, recTypeId, body) =>
    fusionRequest.put(`team/${teamId}/account/${accountId}/autoComplete/${acId}/schedule?version=${version}&recTypeId=${recTypeId}`, body),
  disableAutoCompleteRule: (teamId, accountId, version, recTypeId) =>
    fusionRequest.delete(`team/${teamId}/account/${accountId}/autoComplete?version=${version}&recTypeId=${recTypeId}`),
  findRecTypeAccountSettings: (teamId, accountId, recTypeId) => fusionRequest.get(`team/${teamId}/account/${accountId}/recType/${recTypeId}/settings`),
  setEnableReplaceFiles: (teamId, accountId, recTypeId, version, body) =>
    fusionRequest.put(`team/${teamId}/account/${accountId}/recType/${recTypeId}/setEnableReplaceFiles?version=${version}`, body),
  setBalanceTolerance: (teamId, accountId, recTypeId, version, balanceTolerance) =>
    fusionRequest.put(`team/${teamId}/account/${accountId}/recType/${recTypeId}/setBalanceTolerance?version=${version}&balanceTolerance=${balanceTolerance}`),
  setTransactionTolerance: (teamId, accountId, recTypeId, version, transactionTolerance) =>
    fusionRequest.put(`team/${teamId}/account/${accountId}/recType/${recTypeId}/setTransactionTolerance?version=${version}&transactionTolerance=${transactionTolerance}`),
  setRecStartDateForAutocomplete: (teamId, accountId, acId, version, recTypeId, body) =>
    fusionRequest.put(
      `team/${teamId}/account/${accountId}/autoComplete/${acId}/setRecStartDateForAutocomplete?version=${version}&recTypeId=${recTypeId}`,
      body
    ),
  delete: (teamId, clientId, accountId, version) => fusionRequest.delete(`team/${teamId}/client/${clientId}/account/${accountId}?version=${version}`),
};

export { account };
