import { fusionRequest } from "../fusionRequest";

const uploadedFile = {
  getUploadedFileById: (teamId, fileId) => fusionRequest.get(`team/${teamId}/file/${fileId}`),
  getUploadedFileRowCountsById: (teamId, fileId) => fusionRequest.get(`team/${teamId}/file/${fileId}/rowAndErrorSummary`),
  getUploadedFilesByDate: (teamId, body) => fusionRequest.post(`team/${teamId}/file/list`, body),
  getUploadedFileRowCountsByDate: (teamId, body) => fusionRequest.post(`team/${teamId}/file/listWithErrorsAndRowCount`, body),
  findRecDataByUploadedFileId: (teamId, fileId) => fusionRequest.get(`team/${teamId}/file/${fileId}/rec`),
  getUploadedFileRowCountsByFileIds: (teamId, fileIds) =>
    fusionRequest.get(
      `team/${teamId}/file/rowAndErrorSummary?fileIds=${fileIds}`
    ),
  deleteFiles: (teamId, data) => fusionRequest.delete(`team/${teamId}/file/list`, data),
  deleteRowsFromRec: (teamId, fileId, recId, fileVersion, recVersion) =>
    fusionRequest.delete(`team/${teamId}/file/${fileId}/rec/${recId}?fileVersion=${fileVersion}&recVersion=${recVersion}`),
  downloadFile: (teamId, fileId, responseType) => fusionRequest.get(`team/${teamId}/file/${fileId}/download`, null, responseType),
  uploadFile: (teamId, formData) =>
    fusionRequest.post(`team/${teamId}/file`, formData, null, null, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  reimportData: (teamId, body) => fusionRequest.post(`team/${teamId}/file/reimport`, body),
  getRowDataWithErrors: (teamId, fileId, diagnostic) => fusionRequest.get(`team/${teamId}/file/${fileId}/rowDiagnostics?diagnostic=${diagnostic}`),
};

export { uploadedFile };
