import { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { R, C2, C8, Text, Button, IconAdd, modalInstance } from "@fundrecs/ui-library";
import { PageTitleArea, ManageLayout, MainContainer } from "../layout/Layout";
import { Table } from "../ag-grid/Ag-grid";
import { uiStore, useStore } from "../../store/Store";
import { PATH } from "../../utils/urls";
import { VerticalMenu } from "../reusable/VerticalMenu/VerticalMenu";
import { AUTHORITIES, MODALS } from "../../utils/enums";
import { AccountSetup } from "./AccountSetup/AccountSetup";
import { AuthWrapper } from "../AuthorizationWrapper";
import { DownloadTableButton } from "../reusable/DownloadTableButton";
import { WarningModal } from "../WarningModal";

const Accounts = observer(({}) => {
  const { recTypeStore, teamStore, accountStore, rolesStore } = useStore();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [initialised, setInitialised] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountToDelete, setAccountToDelete] = useState(null);
  const teamId = searchParams.get("teamId") ? Number(searchParams.get("teamId")) : teamStore.getSelectedTeam().id;
  const accounts = accountStore.getAccountsForSelectedTeam();
  let rows = null;
  if (accounts !== null) {
    rows = accounts.map((account) => {
      return { id: account.id, client: account.clientName, clientId: account.clientId, name: account.name, version: account.version };
    });
  }

  useEffect(() => {
    if (!initialised && teamId) {
      accountStore.fetchAccountsForTeam(teamId);
      navigate(`${PATH.FUND}?teamId=${teamId}`, { replace: true });
      recTypeStore.setSelectedRecType(null);
      setInitialised(true);
    }
  });

  const getMenuItems = ({ teamId, data }) => {
    return [
      { key: "fundDetails", label: "Fund Overview", visible: true, link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=0` },
      { key: "recTypes", label: "Reconciliation Types", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=1` },
      { key: "fundIdentifiers", label: "Fund Identifiers", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=2` },
      { key: "subAccounts", label: "Sub Accounts", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=3` },
      { key: "matchingRules", label: "Matching Rules", link: `${PATH.FUND_DETAILS}?teamId=${teamId}&accountId=${data.id}&tab=4` },
      { key: "deleteFund", label: "Delete Fund", optionData: data },
    ];
  };

  const canDeleteFund = async (accountData) => {
    let enabledRecTypes = null;
    if (accountData) {
      enabledRecTypes = await accountStore.fetchEnabledRecTypesForAccount(teamId, accountData.id);
      if (enabledRecTypes.length) {
        uiStore.addNotification(
          "error",
          `There are ${enabledRecTypes.length} rec types enabled for ${accountData.name}. To delete, please disable all rec types for the fund`
        );
      } else {
        setAccountToDelete(accountData);
        modalInstance(MODALS.WARNING).show();
      }
    }
  };

  const deleteAccount = async () => {
    const resp = await accountStore.deleteAccount(teamId, accountToDelete.clientId, accountToDelete.id, accountToDelete.version);
    if (resp.status === 200) {
      uiStore.addNotification("success", `${accountToDelete.name} deleted`);
      accountStore.fetchAccountsForTeam(teamId);
    }
    modalInstance(MODALS.WARNING).hide();
    setAccountToDelete(null);
  };

  const onItemClick = (option, optionData) => {
    if (option === "deleteFund") {
      canDeleteFund(optionData);
    }
  };

  const cols = [
    { headerName: "Client", field: "client", width: 150 },
    { headerName: "Fund Name", field: "name" },
    {
      field: "expandRow",
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        return <VerticalMenu teamId={teamStore.getSelectedTeam().id} data={params.data} getMenuItemsFromRowData={getMenuItems} onItemClick={onItemClick} />;
      },
    },
  ];

  const createFileName = () => {
    const fileName = `All funds for ${teamStore.getSelectedTeam().name}`;
    const fullStopsRemoved = fileName.replaceAll(".", "");
    return fullStopsRemoved;
  };

  return (
    <MainContainer>
      <PageTitleArea
        title="Funds"
        description={<div className="text-medium pb-4">All funds belonging to this team</div>}
        borderBottom={false}
        rightToolbar=""
        props={
          <>
            <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_CREATE])}>
              <Button
                size="md"
                onClick={() => {
                  modalInstance(MODALS.ACCOUNT_SETUP).show();
                }}
                disabled={false}
                color="primary"
              >
                <IconAdd className="btn-sm-svg" />
                <Text size="sm" weight="medium">
                  New fund
                </Text>
              </Button>
            </AuthWrapper>
            <span className="ml-16"></span>
            <DownloadTableButton gridRef={gridRef} createFileName={createFileName} />
          </>
        }
      />
      <ManageLayout>
        <div className="mt-32"></div>
        <Table rowSelection="single" columnDefs={cols} rowData={rows} ref={gridRef} colFlex={true} />
      </ManageLayout>
      <AccountSetup />
      <WarningModal
        title={`Are you sure you want to delete ${accountToDelete ? accountToDelete.name : ""} ?`}
        description={`Deleting this fund will remove all associated reconciliation type configurations, fund identifiers, sub accounts associations and fund aliases. No future reconciliations will be performed and previous reconciliations may be unavailable to view.`}
        buttonClick={deleteAccount}
        buttonText="Delete fund"
      >
        <R props="ml-32 mt-8 mb-8 mr-32">
          <C2></C2>
          <C8 props="pl-0">
            This action is <b>permanent</b> and cannot be reversed.
          </C8>
        </R>
      </WarningModal>
    </MainContainer>
  );
});

export { Accounts };
